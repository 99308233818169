export const locale = {
    lang: 'en',
    data: {
        'about': {
            'title': 'About',
            'jobTitle': '.NET Full Stack Web And Mobile Developer',
            'introductionOfMe': 'Interested in new technologies and working methods. I like to come up with ideas that to permit to improve business processes.',
            'passionateAboutDevelopment':'Passionate about development',
            'mobility': 'Sikar Rajasthan',
            'years': 'Years',
            'graduation': "Master's Of Computer Applicaion",
            'myResearch': '',
            'researchDescription': '',
            'and':'and',
            'followMeOn':'Follow me on',
        },
        'contact': {
            'contactMe': 'Contact Me',
            'notHesitateToContactMe': 'Do not hesitate to contact me',
            'identify': 'Identify',
            'enterIdentify': 'Enter your identify',
            'email': 'Email',
            'enterEmail': 'Enter your email',
            'object': 'Object',
            'enterObject': 'Enter object',
            'message': 'Message',
            'send': 'Send',
        },
        'education': {
            'title': 'Curriculum Vitae',
            'eductionTitle': 'Education',
            'experiencesTitle': 'Experiences',
            'centerOfInterest': 'Center of interest',
            'sport': 'Sport',
            'trips': 'Trips',
            'boardGames': 'Games',
            'languages': 'Languages',
            'fluent': 'Fluent',
            'goodLevel': 'Good level',
            'french': 'Hindi',
            'english': 'English',
            'masterDegreeTwo': "Master Of Computer Application",
            'masterDegreeTwoDesc': "Computer application",
            'masterDegreeOne': "Bachelor's degree",
            'masterDegreeOneDesc': "B.Sc in Maths",
            'bachlorDegree': "12th",
            'bachlorDegreeDesc': "Physics, Chemistry, Maths",
            'university': 'Rajasthan Technical Univeristy Kota',
            'mission':'Mission',
            'technologies':'Technologies',
            'detail':'Detail',
            'tripsCountries':'India',
            'boardGamesPlayed':'Cricket, Badminton',
            'years':'years'
        },
        'header': {
            'home': 'Home',
            'about': 'About',
            'skills': 'Skills',
            'references': 'References',
            'contactMe': 'Contact me',
        },
        'home': {
            'iAmDeveloper': 'I am developer',
        },
        'projects': {
            'title': 'Portfolio',
            'projectDetail':'Project detail',
            'sourceCode':'Source code',
        },
        'references': {
            'title': 'References',
        },
        'skills': {
            'title': 'Skills',
            'technologies': 'Technologies',
            'tools': 'Tools',
            'methodologies': 'Methodologies'
        },
    }
}