export const locale = {
    lang: 'fr',
    data: {
        'about': {
            'title': 'A propos',
            'jobTitle': "Développeur d'applications web et mobiles",
            'introductionOfMe': "Intéressé par les nouvelles technologies et méthodes de travail. J'aime proposer des idées qui permettent d'améliorer les processus d'affaires.",
            'passionateAboutDevelopment':' Passionné par le développement',
            'mobility': 'Mobile dans toute la France',
            'years': 'ans',
            'graduation': "Master 2 Informatique",
            'myResearch': 'Recherche de contrat CDI',
            'researchDescription': "Je recherche une entreprise innovante et centrée sur l'utilisateur pour répondre au mieux aux besoins du client, de son idée à la livraison finale de son projet, ou participer à l'amélioration d'un projet existant.",
            'and':'et',
            'followMeOn':'Suivez-moi sur',
        },
        'contact': {
            'contactMe': 'Contactez moi',
            'notHesitateToContactMe': "N'hésitez pas à me contacter",
            'identify': 'Identifiant',
            'enterIdentify': 'Entrez votre identifiant',
            'email': 'Email',
            'enterEmail': 'Entrez votre Email',
            'object': 'Objet',
            'enterObject': "Saisissez l'objet",
            'message': 'Message',
            'send': 'Envoyer',
        },
        'education': {
            'title': 'Curriculum Vitae',
            'eductionTitle': 'Éducation',
            'experiencesTitle': 'Expériences',
            'centerOfInterest': "Centre d'intérêts",
            'sport': 'Sport',
            'trips': 'Voyages',
            'boardGames': 'Jeux de société',
            'languages': 'Langues',
            'fluent': 'Courant',
            'goodLevel': 'Bon niveau',
            'french': 'Français',
            'english': 'Anglais',
            'masterDegreeTwo': "Master 2 Informatique E-services",
            'masterDegreeTwoDesc': "Master en services numériques",
            'masterDegreeOne': "Master 1 Informatique",
            'masterDegreeOneDesc': "Master Général Informatique",
            'bachlorDegree': "Licence 3 Informatique",
            'bachlorDegreeDesc': "Licence en informatiques",
            'university': 'Université de Lille, FR',
            'mission':'Mission',
            'technologies':'Téchnologies',
            'detail':'Détail',
            'tripsCountries':'Belgique, Espagne, Pays-Bas, Suisse',
            'boardGamesPlayed':'Jeu de dames et de dominos, Among US, Ludo, ..etc',
            'years':'ans'
        },
        'header': {
            'home': 'Accueil',
            'about': 'A propos',
            'skills': 'Compétences',
            'references': 'Recommandations',
            'contactMe': 'Me Contacter',
            
        },
        'home': {
            'iAmDeveloper': 'Je suis développeur',
        },
        'projects': {
            'title': 'Projets',
            'projectDetail':'Détail projet',
            'sourceCode':'Code source',
        },
        'references': {
            'title': 'Recommandations',
        },
        'skills': {
            'title': 'Compétences',
            'technologies':'Téchnologies',
            'tools':'Outils',
            'methodologies':'Méthodologies'
        },
    }
}