<div class="page-content">
    <app-header></app-header>
    <app-home></app-home>
    <app-about></app-about>
    <app-skills></app-skills>
    <app-curriculum-vitae></app-curriculum-vitae>
    <app-projects></app-projects>
    <app-references></app-references>
    <app-contact></app-contact>
    <footer class="footer">
        <p> Copyright © 2022-<span>{{currentYear}}</span> By Kapil Sharma.</p>
    </footer>
</div>