export const projectsEn = [
    {
        id:"teamTraveler",
        name: "Team Traveler",
        technologies: 'Android Studio, Java, FireBase, MVP, NoSql',
        images: [
            {
                title:'Trip',
                src: "assets/images/voyages.webp",
            },
            {
                title:'Trip',
                src:"assets/images/voyage.webp",
            },
            {
                title:'Housing',
                src: "assets/images/logements.webp",
            },
        ],
        url: "https://play.google.com/store/apps/details?id=team.traveler.teamtraveler",
        detail:"The application will allow you to organize group trips easily and quickly. Vote for dates, transport, accommodation or even activities. Thanks to this <a title='Download on Google Play' href='https://play.google.com/store/apps/details?id=team.traveler.teamtraveler'>application</a> no more problems with choices or disagreements.",
        codeSourceUrl:'https://github.com/zinedineBenkhider/team_traveler',
        detailIsDisplayed: false,
    },
    {
        id:"mautic",
        name: "Mautic Monitoring",
        technologies: 'Android Studio, Java, Rx_Java, Retrofit, MVP, Rest',
        images: [
            {
                title:'Marketing campaigns',
                src:   "assets/images/compagnes.jpg",
            },
            {
                title:'Campaign detail',
                src :"assets/images/compagne-detail.jpg",
            },
            {
                title:"Email performance",
                src: "assets/images/email-detail.jpg",
            },
            {
                title:"Performance of an asset",
                src: "assets/images/asset-detail.jpg" 
            },
            
        ],
        url:'#portfolio',
        detail:"<p>Mautic is an open source automatic marketing tool</p> <p>The application allows you to have dynamic and detailed reports, to analyze individual campaign trends.<br> A visually user-friendly dashboard gives you a unique quick view of the performance of: your digital campaigns, assets, and forms. </p> <p>The application is available in French and English.</p>",
        codeSourceUrl:'https://github.com/zinedineBenkhider/mautic_monitoring',
        detailIsDisplayed: false,
    },
    {
        id:"pokedex",
        name: "Pokedex",
        technologies: 'Java, J2EE, SpringBoot, TDD, MySQL, BootStrap, Services, Deploiment',
        images: [
            {
                title:'Pokedex',
                src:   "assets/images/pokedex.png",
            },
            {
                title:'Trainers',
                src :"assets/images/trainers.png",
            },
            {
                title:"Profile",
                src: "assets/images/profile-trainer.png",
            },            
        ],
        url:'#portfolio',
        detail:"<p>Display of the details of pokemon and their trainers.</p> <p>The two micro-service application contains: pokemon management and trainer management, which exposes a REST/JSON communication channel.<br>The 3rd WEB-UI micro-service connects to both micro-services.</p>",
        codeSourceUrl:'https://github.com/ifi-2019/trainer-ui-zinedineBenkhider',
        detailIsDisplayed: false,
    },
    {
        id:"portfolio",
        name: "Portfolio",
        technologies: 'Angular11, Html5, CSS, TypeScript, BootStrap',
        images: [
            {
                title:'Portfolio',
                src:   "assets/images/portfolio.png",
            },
        ],
        url:'https://medium.com/zinedine-projects/beautiful-cv-portfolio-72921a198640',
        detail:"<p> CV in the form of a responsive WEB site that I put in open-source on GitHub accompanied by a publication of a <a title='See the article' href='https://medium.com/zinedine-projects/beautiful-cv-portfolio-72921a198640' target='_blank'>article</a> on medium. </p>",
        codeSourceUrl:'https://github.com/zinedineBenkhider/portfolio',
        detailIsDisplayed: false,
    },
    {
        id:"riddle",
        name: "Transfer Riddle",
        technologies: 'Java, J2EE(Web Services), JavaScript, Html5, CSS, BootStrap',
        images: [
            {
                title:'Riddle',
                src:   "assets/images/riddle.png",
            },
        ],
        url:'https://transfer-riddle-solver.herokuapp.com/index.html',
        detail:"Display of the steps for solving the decanting puzzle. Given two empty tanks of capacity X and Y, how to obtain precisely T liters in one of the two tanks (T being an integer). You can fill or empty a tank , and transfer the contents from one to the other, until the source is empty or the destination full.",
        codeSourceUrl:'https://github.com/zinedineBenkhider/transfer_riddle_client',
        detailIsDisplayed: false,
    },
];

