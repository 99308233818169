export const experiencesEn = [
    {
        title: "Lead Software Engineer",
        company: 'Siemens Healthineers',
        period: "Dec 2022 - Till Date",
        technologies: 'C#, .NET, Angular, Web API, .NET Core, Azure, SQL, Docker',
        missions: [
             "Lead Full Stack Developer",
        ],
        detailIsDisplayed: false,
    },
    {
        title: "Lead Software Engineer",
        company: 'Epam Systems',
        period: "Feb 2020 - Dec 2022",
        technologies: 'C#, .NET, Angular, Web API, .NET Core, Azure, SQL, Cosmos, Mongo DB',
        missions: [
            "Development of a Hybrid Mobile Application.",
            "Web API Implementation.",
            "Team Handling",

        ],
        detailIsDisplayed: false,
    },
    {
        title: "Web Specialist",
        company: 'Applied Information Services',
        period: "March 2019 - Feb 2020",
        technologies: 'C#, .NET, Chiffrement AES-256',
        missions: [
            "Web Deployment",
            "Bug Fixes"
        ],
        detailIsDisplayed: false,
    },
    {
        title: "Senior Software Engineer",
        company: 'Pratham Software',
        period: "Nov 2017 - Feb 2019 (1 year)",
        technologies: 'C#, .NET, Angular, Web API, MVC, Azure, SQL,',
        missions: [
            "Application Development And Deployment",
            "Client Communication",
            "Migration"
        ],
        detailIsDisplayed: false,
    },
    {
        title: "Senior Software Engineer",
        company: 'Akeo India (Adelante Techno Labs)',
        period: "Dec 2014 - Oct 2017",
        technologies: 'C#, .NET, Chiffrement AES-256',
        missions: [
            "Application Development And Deployment",
            "Client Communication",
            "Migration",
            "Team Lead"
        ],
        detailIsDisplayed: false,
    },
];

